<template lang="pug">
  TiiaForm(:data="data")
  //- pre {{ data }}
</template>

<script>
import TiiaForm from './TiiaForm'

export default {
  name: 'TiiaEdit',
  components: {
    TiiaForm
  },
  data () {
    return {
      data: {
        GroupId: undefined,
        EntityId: undefined,
        PicName: undefined,
        //- MaxQps: 10,
        //- GroupType: '5'
      }
    }
  },
  methods: {
    async getDetail() {
      if (!this.$route.params.id) return;

      const result = await this.$http.get(`tiia/DescribeGroups?id=${this.$route.params.id}`);
      this.data = result;
    },
  },
  created() {
    this.getDetail();
  }
}
</script>

<style lang="less" scoped></style>