<template lang="pug">
.content
  section.container-fluid.p-t-20
    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item
        router-link(to="/tiia") 图片管理
      a-breadcrumb-item {{ data.id ? '编辑图片' : '新增图片' }}

    .m-t-15.m-b-20
      h1.section-title.inline {{ data.id ? '编辑图片' : '新增图片' }}
      a-button.m-l-15(size="small" @click="() => { this.$router.push({ name: 'Tiia Management' }) }") 图片管理

  .container-fluid.clearfix(style="margin-right:320px;")
    a-form-model.tiia-formz(ref="form" :model="data" :rules="rules")
      .inner-content-right
        .editor-wrap
          a-card.m-b-15(size="small" :bordered="false" :bodyStyle="{ padding: 0 }")
            a-form-model-item.m-b-0(required prop="PicName")
              a-input(size="large" v-model="data.PicName" placeholder="图片名称")

          a-card.m-b-15(size="small" :bordered="false" :bodyStyle="{ padding: 0 }")
            a-form-model-item.m-b-0(required prop="EntityId")
              a-input(size="large" v-model="data.EntityId" placeholder="物品ID")

          a-card.m-b-15(size="small" title="图片 Url:")
            a-form-model-item.m-b-0
              a-textarea(v-model="data.ImageUrl")

          a-card.m-b-15(size="small" title="图片 base64 数据:")
            a-form-model-item.m-b-0
              a-textarea(v-model="data.ImageBase64")

          a-card.m-b-15(size="small" title="自定义内容:")
            a-form-model-item.m-b-0
              a-textarea(v-model="data.CustomContent")

          a-card.m-b-15(size="small" title="自定义标签:")
            a-form-model-item.m-b-0
              a-textarea(v-model="data.Tags")

      .secondary-sidebar-right
        a-card.m-b-15(size="small" title="发布")

          a-form-model-item.m-b-15(label="图库名称:" required prop="GroupId")
            a-select.full-width(v-model="data.GroupId")
              a-select-option(:value="item.GroupId" v-for="(item, index) in Groups" :key="index") {{ item.GroupName }} ({{ item.MaxCapacity }})
            //- small.block 图库可容纳的最大图片数量

          a-button.submit(type="primary" block @click="submit") 提交
          
        a-card.m-b-15(size="small" title="debug")
          pre(style="font-size:11px;") {{ data }}
</template>

<script>
//- import { mapState } from 'vuex'

import vcTinyMCE from '@/components/vcTinyMCE'

export default {
  name: 'TiiaForm',
  components: {
    'vc-tinymce': vcTinyMCE
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      Groups: [
        {
          Brief: "desc",
          CreateTime: "2022-05-30 16:36:16",
          GroupId: "test",
          GroupName: "test desc",
          GroupType: 5,
          MaxCapacity: 8888,
          MaxQps: 10,
          PicCount: 0,
          UpdateTime: "2022-05-30 16:36:16"
        }
      ]
    }
  },
  computed: {},
  watch: {},
  methods: {
    async submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) return this.$message.error('提交失败');
        try {
          const method = this.data.id ? 'put' : 'post';
          //- const url = this.data.id ? `tiia/${this.data.id}`: 'tiia/CreateGroup';
          const url = this.data.id ? `tiia/${this.data.id}`: 'tiia/CreateImage';
          await this.$http[method](url, this.data);
          this.$refs.form.resetFields();
          this.$message.success('提交成功');
        } catch(err) {
          this.loading = false;
          this.$message.error(err.message);
        }
      })
    }
  },
  created() {
    
          //- this.$http.get('tiia/DescribeGroups')
  },
}
</script>

<style lang="less">
.secondary-sidebar-right {
  float: left;
  width: 310px;
  margin-right: -310px;
}
.inner-content-right {
  float: left;
  width: 100%;
  margin-right: 15px;
}
.tiia-form {
  border-top: solid 1px #ccc;
  padding: 10px 0;

  .ant-divider { margin: 16px 0;}
  .ant-form-item {
    margin: 12px 0;
  }
  .ant-card {
    .ant-form-item {
      margin: 6px 0;
      label { font-size: 12px;}
    }
    .ant-form-item-label { line-height: 35px;}
    .ant-form-item-control { line-height: 36px;}
    .ant-input,
    .ant-input-number {
      height: 30px;
    }
  }
}
</style>